//<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Settings') }}</div>
          </template>
        </Toolbar>

<!--        <Spinner v-if="user && isLoading"></Spinner>-->
<!--        <div class="p-mt-4 p-d-flex p-jc-start p-mt-5" v-if="!isLoading && !$store.state.tabletLayout">-->
        <div class="p-mt-4 p-d-flex p-jc-start p-mt-5">
          <div class="p-mr-2">
<!--            <span class="p-mr-3">Close changes to sales invoices due:</span>-->
            <div class="p-float-label">
              <Calendar class="settings-input"
                        id="closed-financial-period-due-date"
                        @date-select="changeClosedFinancialPeriodDueDate"
                        @blur="changeClosedFinancialPeriodDueDate"
                        v-model="closedFinancialPeriodDueDate"
                        @show="toogleDropdownOverlayVisibility(true)"
                        @hide="toogleDropdownOverlayVisibility(false)"
                        :dateFormat="settings.dateFormat"
                        :showIcon="true"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        :showButtonBar="true"
                        :selectOtherMonths="true"
                        autocomplete="off"/>
              <label for="closed-financial-period-due-date">Financial period closed due<span class="warning-color">*</span></label>
            </div>
<!--            <small class="p-invalid" v-if="submitted && !closedFinancialPeriodDueDate">{{ $t('Required field') }}</small>-->
          </div>
<!--          <Button :disabled="!closedFinancialPeriodWasChanged" @click="changeClosedFinancialPeriod" class="p-button-raised p-mr-2" icon="ti-save" :label="$t('Save')"></Button>-->
<!--          <Button @click="changeClosedFinancialPeriod" class="p-button-raised" icon="ti-save" :label="$t('Save')"></Button>-->
          <Button @click="changeBranchDataVariable(closedFinancialPeriodDueDate, 'financial_period_closed_due', true)" class="p-button-raised" icon="ti-save" :label="$t('Save')"></Button>
        </div>
        <div class="p-mt-4 p-d-flex p-jc-start p-mt-5">
          <div class="p-mr-2">
            <!--            <span class="p-mr-3">Close changes to sales invoices due:</span>-->
            <div class="p-float-label">
              <InputNumber id="branch-appointment-first-date"
                           class="settings-input"
                           :locale="computedNumberInputLocale"
                           v-model="appointmentFirstDay"
                           :min="0"
                           :max="30"
                           showButtons
                           autocomplete="new-password"/>
              <label for="branch-appointment-first-date">Appointment first day<span class="warning-color">*</span></label>
            </div>
            <!--            <small class="p-invalid" v-if="submitted && !closedFinancialPeriodDueDate">{{ $t('Required field') }}</small>-->
          </div>
          <!--          <Button :disabled="!closedFinancialPeriodWasChanged" @click="changeClosedFinancialPeriod" class="p-button-raised p-mr-2" icon="ti-save" :label="$t('Save')"></Button>-->
<!--          <Button @click="changeAppointmentFirstDay" class="p-button-raised" icon="ti-save" :label="$t('Save')"></Button>-->
          <Button @click="changeBranchDataVariable(appointmentFirstDay, 'appointment_first_date')" class="p-button-raised" icon="ti-save" :label="$t('Save')"></Button>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
// import constants from '@/constants'
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import calculateMixins from "@/mixins/calculateMixins";
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ formatMixins, overlayVisibilityMixins, calculateMixins, httpMixins ],
  // components: { Spinner },
	data() {
		return {
      appointmentFirstDay: null,
      closedFinancialPeriodDueDate: null,
      isLoading: false,
      // closedFinancialPeriodWasChanged: false,
      submitted: false,
      settings
		}
	},
  watch: {
    '$store.state.branchData.financial_period_closed_due'(value) {
      if (value) {
        this.closedFinancialPeriodDueDate = new Date(value * 1000)
      }
    },
    '$store.state.branchData.appointment_first_date'(value) {
      if (value) this.appointmentFirstDay = value
    }
  },
  mounted() {
    if (this.$store.state.branchData) {
      if (this.$store.state.branchData.financial_period_closed_due) {
        this.getFinancialPeriodDateFromStoreState()
      }
      if (this.$store.state.branchData.appointment_first_date) {
        this.getAppointmentFirstDateFromStoreState()
        // this.appointmentFirstDay = this.$store.state.branchData.appointment_first_date
      }
    }
	},
  methods: {
    getFinancialPeriodDateFromStoreState() {
      if (!this.$store.state.branchData?.financial_period_closed_due) return
      this.closedFinancialPeriodDueDate = new Date(this.$store.state.branchData.financial_period_closed_due * 1000)
    },
    getAppointmentFirstDateFromStoreState() {
      if (!this.$store.state.branchData?.appointment_first_date) return
      this.appointmentFirstDay = this.$store.state.branchData.appointment_first_date
    },
    changeClosedFinancialPeriodDueDate() {
      this.closedFinancialPeriodDueDate = this.calculateDayEndDate(this.closedFinancialPeriodDueDate)
    },
    // async changeClosedFinancialPeriod() {
    //   this.submitted = true
    //   const value = this.closedFinancialPeriodDueDate ? +new Date(this.closedFinancialPeriodDueDate / 1000) : null
    //   const branchId = this.$store.state.branchData?.id
    //   const variable = 'financial_period_closed_due'
    //
    //   // console.log(+this.closedFinancialPeriodDueDate, +this.closedFinancialPeriodDueDate / 1000, branchId)
    //   try {
    //     // const { status, data } = await httpClient.post(
    //     //     `branch/change-closed-financial-period-due-date`,
    //     //     { period, branchId }
    //     // )
    //     const { status, data } = await httpClient.post(
    //         `branch/change-branch-variable`,
    //         { value, branchId, variable }
    //     )
    //     if (status === 200 && data?.success) {
    //       this.$store.commit('updateBranchDataVariable', 'financial_period_closed_due', value)
    //       this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
    //     } else if (data?.error) {
    //       this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
    //     } else {
    //       // this.getAppointmentFirstDateFromStoreState()
    //       this.$toast.add({severity:'error', detail: this.$t('Unknown error'), life: settings.toastLife});
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   } finally {
    //     this.getFinancialPeriodDateFromStoreState()
    //     // this.closedFinancialPeriodWasChanged = false
    //     this.submitted = false
    //   }
    // },
    // async changeAppointmentFirstDay() {
    //   this.submitted = true
    //   const value = this.appointmentFirstDay
    //   const branchId = this.$store.state.branchData?.id
    //   const variable = 'appointment_first_date'
    //
    //   try {
    //     const { status, data } = await httpClient.post(
    //         `branch/change-branch-variable`,
    //         { value, branchId, variable }
    //     )
    //     if (status === 200 && data?.success) {
    //       this.$store.commit('updateBranchDataVariable', 'appointment_first_date', value)
    //       this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
    //     } else if (data?.error) {
    //       // this.getAppointmentFirstDateFromStoreState()
    //       this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
    //     } else {
    //       // this.getAppointmentFirstDateFromStoreState()
    //       this.$toast.add({severity:'error', detail: this.$t('Unknown error'), life: settings.toastLife});
    //     }
    //   } catch(err) {
    //     // this.getAppointmentFirstDateFromStoreState()
    //     this.showError(err)
    //   } finally {
    //     this.getAppointmentFirstDateFromStoreState()
    //     // this.closedFinancialPeriodWasChanged = false
    //     this.submitted = false
    //   }
    // },
    async changeBranchDataVariable(value, variableName, isDate = false) {
      this.submitted = true
      // const value = this.appointmentFirstDay
      const branchId = this.$store.state.branchData?.id
      // const variable = 'appointment_first_date'
      if (isDate) {
        value = value ? +new Date(value / 1000) : null
      }

      try {
        const { status, data } = await httpClient.post(
            `branch/change-branch-variable`,
            { value, branchId, variableName }
        )
        if (status === 200 && data?.success) {
          // this.$store.commit('updateBranchDataVariable', 'appointment_first_date', value)
          this.$store.commit('updateBranchDataVariable', variableName, value)
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
        } else if (data?.error) {
          // this.getAppointmentFirstDateFromStoreState()
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          // this.getAppointmentFirstDateFromStoreState()
          this.$toast.add({severity:'error', detail: this.$t('Unknown error'), life: settings.toastLife});
        }
      } catch(err) {
        // this.getAppointmentFirstDateFromStoreState()
        this.showError(err)
      } finally {
        this.getAppointmentFirstDateFromStoreState()
        // this.closedFinancialPeriodWasChanged = false
        this.submitted = false
      }
    },
	},

}
</script>
<style lang="scss" scoped>
.settings-input {
  width: 280px
}
</style>